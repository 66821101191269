export const allowlistAddresses = [
  ["0x318b811b59ab9fa6b8adcdd5a5eae70fc5686efa",1],
  ["0x018988e3a863205de9f227f1ca7a38221ea5ce2d",1],
  ["0x832d725e181a32bc569551460f5bb3b619ef1b9b",1],
  ["0x1d028d6ffefbc9ec660aa30385733415f20f78fd",1],
  ["0xeb03aaec3754c1c1953ad52b42cd234be78f03d0",1],
  ["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",1],
  ["0x9643cab1125c63cc0b9b06183faf49ab891b26bd",1],
  ["0x5eab4af9be5e3ebcb40c0b08a805c182c5cccec2",1],
  ["0xb3c70d8be190268c61898d86ff4f510834240558",1],
  ["0x8d55c04129d59e99cb6cdc3dcbc46ee339bb8293",1],
  ["0xd6814f968f06feb8fbdf38e6ea598720b094e2e4",1],
  ["0x83f38a83575a825da7f4d6ea8be171b3ded79e37",1],
  ["0x1f8b98db321ab2816b517bbeef905bb06473112c",1],
  ["0xa78f924883d36a85e1aba2edadce486cb84b3975",1],
  ["0xa5dcd3ae59814eccdbcbc2cf1a28f2a10cf63b1f",1],
  ["0xdfff3e788bf2e8e487df1c5d1b1f5da464caac4f",1],
  ["0x112cfd399062161ddaea99bc30c97013d61927c5",1],
  ["0xd3b2a8dad4b14e02e9f7c73922e1e43a0e211ed9",1],
  ["0xe5e8bd70e888d26448492758e7b7a7efbc1f9e07",1],
  ["0x5005c91aaa511a0faefdc4fda429404a93d98bbe",1],
  ["0x0a1a1569d5d1e69d2df790f9de59891fe6b59dd9",1],
  ["0x2bc45d459b11ccc88be0cbda1a59b3df14761ba5",1],
  ["0x253f251bb03729f688714ff0e03e3e34d186049f",1],
  ["0x790d7ff1555b9b951aef8c59728aefe2a326dea0",1],
  ["0x88f115779a78403d81d140cb39e815bff4253537",1],
  ["0xb23eb4efb463bd20cbfe59a9e2a18d8fbead9c31",1],
  ["0xe2a92ada21d427212846b147f07e52bff44f6fbd",1],
  ["0xc579cf5b12010db23735105016573cda46b64cc6",1],
  ["0x458a9b6b690c0b3ccaa3067d93619762cd2d9e96",1],
  ["0xe977315fb1650192359c56a8efd90b17ff7cc6f5",1],
  ["0xcf1f5124948bb15f6728a804a37b75b3a3d9db4a",1],
  ["0x58276b5e37f2a9b57ffda600ff94d02e26cebd59",1],
  ["0xca689c3234de16b8f5c5a7f94e740ae902ec7e6f",1],
  ["0x04dcc8e0e45fe45e9f36ace0e8a98b8a9c578b8d",1],
  ["0xde38c6964f840afeeb6d891cb4f6b132498579ae",1],
  ["0x63c636f17d8424394ae6712f8540b94bec8d2192",1],
  ["0x50889e629ea47888a38b0b256bb0212183b6a1e9",1],
  ["0x09ac4fe95aba98490183d7a3128ed4d50ab81f49",1],
  ["0x26e71ea32074f01c7c9dbd67a1ec92ae6a950d2b",1],
  ["0x5672699dd10a3b07637066a418ada4fdf6846618",1],
  ["0x284978427f4c9d72716539a6a2600a7a02b06095",1],
  ["0x2a469296c6d10a6496bf145853933a73913eae32",1],
  ["0x6cbf5c6e3d1ffc447807c1b2bd4c8eab39f00ace",1],
  ["0xad8a514d9ce843bf381e8865b28b8a400969ed3a",1],
  ["0x2ddb4231c2107a7666ac828ee4cb09d971e99d8d",1],
  ["0xf756a2dae5e5d34185e6f1e57efe02bd7249d617",1],
  ["0x04131e8d773acf5641f1cd726285ae1a2b3ac3a1",1],
  ["0xffeeba70c3ebcdfe87368c06a5d8990d306b1899",1],
  ["0xe272f9a5c6126ec129cf68d2ccd6aa83f209b355",1],
  ["0xe1b6976068114fc08ca69369c92a7c377e7570e0",1],
  ["0x06bac69d925002b5b1e5553b8c20f997144905c0",1],
  ["0xac850b9ee0817a061b79d5e63f94cb9ed5250cac",1],
  ["0x03f66a25d5746e13a64295f7b57622155f9af9f8",1],
  ["0x2c51f8b742d1d2c7d366d1ce414f697d2109a909",1],
  ["0x5d94c7781d25e6e67f71ffa540f2021bc164f8f8",1],
  ["0x45915331b29b55e73fa48862d89e0dd9be320942",1],
  ["0x5bf6dbee2798ca0674e5703dfc2fc32855245e55",1],
  ["0x1c4d8ad286498e02725522988775f63fce93a883",1],
  ["0x38ce69941e72dca535f32ca2da6d06ac8bed0dbe",1],
  ["0xead21833a7c137d525e127916832a529c41dfebb",1],
  ["0x1e1dc93e06e44cae96e3dd20cb10bfc9861d3dcc",1],
  ["0xe736727e6061565ee3fa058728b45d28bbdaaf6b",1],
  ["0x4ca30cedd701a91cea1df47134f0eaf1fa389195",1],
  ["0xf7fe090c7a98dc9b3078a0e569ba1d79737ffa35",1],
  ["0x37e4a711eb2e20b3892706f90020a2f24fa82f9c",1],
  ["0x6ce4daa688b5977a461f8a6be23a9a8d7a52d580",1],
  ["0x6dd5982348b8faab1738d64bc0e5895918ffa68f",1],
  ["0x649ffa1a486400d91dd99aa974e29e4196332cca",1],
  ["0x8586b1bcffa41ffba0c6d658f7ff54edc4667597",1],
  ["0xa88ff6bfb02e175fb8b69a790c91eea527eb19b5",1],
  ["0xdf0ed988d08fabf41c65cf7c52b90c32cf2d24ae",1],
  ["0xebdc5e356c595b593ed12daa33e92465bc46cc90",1],
  ["0x130f994e85b9c81aa8aa63e25fc05ff27f16ef20",1],
  ["0x8a4ccf0e428aabe1ee527a0f3f68e268e828c733",1],
  ["0x601ed39d52a5e015d8e0caaaabd19107bad2f9a4",1],
  ["0xa46666810794069cc6ee3cedb1aa62904db24553",1],
  ["0x2867ee9e294954ec31517e2d1e8df9e82d742a33",1],
  ["0xeb6aeaee8116713fbf9fe64ccb7c300f2de33b0a",1],
  ["0x4fdf77193efe5315b529b5c4d620a1cb7ef00d22",1],
  ["0xaf6b82050a12b5caca3373b0d2771a953afaa624",1],
  ["0x816978c6cc3eede8823aed2b1d2db904505bea4b",1],
  ["0x908d173f26b04e4c0bbcd70d7e7bda9aea81837a",1],
  ["0x6bdc53740309acd1e6785093494bfb4bab862694",1],
  ["0x7cb2f06e01697f56a38e0da8b9df5ab1d714fa4e",1],
  ["0xa72748164b060d84911d1e710341a3774d57a831",1],
  ["0xda4cb47f4141df6b176d7b5ebd1ea21cc0fb83ef",1],
  ["0xf4560b1ef2b8840bb6666d39e7c8be916c76cf5b",1],
  ["0xdca244064279e847a38d80dfdbf70afeb01313a3",1],
  ["0x8fb43c0a89f028a6747564e48e816abf27cd439e",1],
  ["0x0ee4bdbd6b21213b2ed4ec6337f209624faac0a0",1],
  ["0x0c0508e09f1fd1ec1cd47cb9050b9ce4fb139676",1],
  ["0xbc8f352c82c1355d54186dc5b3397ed2e04b62a7",1],
  ["0xe33644c3c2f7cd27f56c5e841f2f963d1b6d655f",1],
  ["0x224f151bd65ea89938f7c6aead83bfe4cf701c3f",1],
  ["0x0e952164a31376449703c27f74fdccd7d5b4220a",1],
  ["0xfbf6aec6d687d313e1ff1187f53580dfffa4cb55",1],
  ["0x7da93c40324089cbf5e41bbd2f89cfb97641ddad",1],
  ["0xcb117a6c52f2830dfa9f4c7103e9ad09bb880cd4",1],
  ["0x42013faf91996f47ebcdf1b42b13fa33f9ff5c5d",1],
  ["0x54d7f0ffc91fe40b72f50374b324c8f005b90485",1],
  ["0xdcfff5808d0b5b9c5e874fcdb584d593951a57d5",1],
  ["0x7aa07bdd8955833f45a946a735381131610ddd64",1],
  ["0xb3384dfd46fefdf209d2c896d3e06e584b304c2e",1],
  ["0xabc92a41ca0a2999214952f4a87f281a972c6d67",1],
  ["0x311b58b8c84bbcd61e4efd2fb155e7a71fc71594",1],
  ["0xce2d4fb47140fad9c346e1c34d5af8fe309b8da1",1],
  ["0x354029b0d5c417fed5f22522d3a1325b5397df8e",1],
  ["0x689af9e2afb989a4a25530c77a388e5bd5d38d9e",1],
  ["0x70dbea0054ea488cd23b05be02c1581d026ac983",1],
  ["0x3e5d6d156dfeab4ae977f5d6a5bb555545274b33",1],
  ["0xe366f9c12151e71d2a034d550cf5f7572f1154b2",1],
  ["0xff24fcad8b921dfbd1c1192f0c77fadc3c42f43c",1],
  ["0xe49d5be6c9f8ff32bba6fa0ec26c8b9bbb23b0a8",1],
  ["0x4b9053a21971185098d71976d28273fbb995a75b",1],
  ["0x0712bc30bf0f88c4651f2f43c169b9b0adc2c0d4",1],
  ["0xa4f9921b135aebce8bf3505ed7de52ee1b6118cc",1],
  ["0xc1d8432beb3844fcbbdfd630121181dd35951d14",1],
  ["0xadb76d1e4e284501ad9485f645da39b8a3e355b8",1],
  ["0x3d3b69457ce7e7998f19e85e018b5a296aed781e",1],
  ["0x033fa4a6e637928ee739173984100ab02a095d1e",1],
  ["0xb50d3290e189333648c43d25e21c0c979a081bfe",1],
  ["0x40af92973552cd9452dafa305bbc30634896433c",1],
  ["0xd311c04936a573309e928835b105bb79acc4b079",1],
  ["0xaccd4c4c068b98e3fa3c731b35b053e227990e61",1],
  ["0x6e967c7978af1f6bc434a5088f88267a2987705f",1],
  ["0x5c12375f018cf365bca2569cddb81560982d465a",1],
  ["0xdc0d37720cd95cd5a14c20075a3eaf76ea8f8203",1],
  ["0x3f4c51297b9a6b8ffa086fe6aa5c1da66207fc3e",1],
  ["0x5f3b9f6510527046c63a26a03cf87a3d24430808",1],
  ["0xdb37859e42f7ea074d0390e0690132f4d3b2de91",1],
  ["0xe30344525a83944fff76e2082f648db732266a3c",1],
  ["0x1cbceee95ea7bc55fde81933ba73b0b9400e2c1c",1],
  ["0xa546ee534805f9968e5a84a9cb48860779e45e13",1],
  ["0x2e82124e9631edc3f63f8614a5c4cdb3ead38444",1],
  ["0x3b57857d6b5901706228a92bda899c85ee7707cf",1],
  ["0xf1faafa985fdc96513ba4acf72d0b6bf6903b7cf",1],
  ["0xf30c474027979048ff63bdd24c36fb4e65ee7b21",1],
  ["0xeefbce875607133f004062a922fc136e67324d31",1],
  ["0xe6555e37a51ed29fc095f2b5ff8b4df62d27bf10",1],
  ["0x821e49e8e8cdbcd46a1e1593836d6a91abba49e9",1],
  ["0x5c2866f3e80d4d24909efb476243398e0d0b79a2",1],
  ["0x88afbcb7e71c13ce541c1d183b015276a5cea45c",1],
  ["0x9a22b919d9c2809dfcfc83947fde0edf631cbd78",1],
  ["0x307487fc175c894f2186778ce52d9b6e5d6ad9c9",1],
  ["0x07513bb7c13f6a1928648f62e7ed41d560c6443c",1],
  ["0x4e0a6bfd97929b38f3692f205ab0039096cada7a",1],
  ["0xc0166b8c57a976c429ea00b2b7a50d484b46cdeb",1],
  ["0xc43311ca630fbe5fd190d863a8658f862614d77e",1],
  ["0x43f24134f2afae3d92ce636dc3386bc6cb8d16f0",1],
  ["0x22fba0bd117d7c29a41c9f75bba432f415a79e5b",1],
  ["0x17982d4224d781187617e01a4b7f4df7cbcf317b",1],
  ["0x58e74e94e836d36c27fdca268d2f0fbd4b76b24a",1],
  ["0x2c4387f40b1175ba5779d398302640a20174bff7",1],
  ["0xf4a2d18354e98ef2cde414ee37bb6ccf7a583683",1],
  ["0x85457cdf12ecc15ae5c8a917e15a5fbef598539c",1],
  ["0xe808b70a46b473043fe56383bb65c62f1085abb5",1],
  ["0xbbf7d8e3b845918024db9e95a050f3dfa2a24db1",1],
  ["0x8b014966918293e62994fe05d7d3e75b84f98e9a",1],
  ["0x0ca943f41d3dfc285b7ba83209f1d27dd13eed59",1],
  ["0xebd8cb306ac616b0dc592fd316e8943485dec13b",1],
  ["0xad6b0186fe8621ecf3a78e098f4de04be972e283",1],
  ["0xc05a9a1accd62afde58c9a233d4f597661dfbcd8",1],
  ["0x1a2842d14b59c6eeafea82b0037451848b719f68",1],
  ["0xba484bf45d169c5fd799a339bc3c2c930bb62a04",1],
  ["0xaaca9e40d2c4ad9bdd9c12600c4a720ad93baf2a",1],
  ["0x972ccba923b9970504057dd185c70036a0d70824",1],
  ["0x911d3a600009bfe676dae6e0dcafb76b37c027aa",1],
  ["0xc54f0b9892df3411e4cead610c9f6fc0055828c9",1],
  ["0x83e958aa52023ec40de1dc30276addeea6de4028",1],
  ["0x94aa05015e17ebd6da5b3ec1150c4b35850e5740",1],
  ["0xd64bce64e9d2567f06aa1d160c3848b1efa5f2a4",1],
  ["0x27ca0cfc3da1f074963b77c13339ee7203cc3a7a",1],
  ["0xf41cbbab175b22f0b9017ac4088e2d5f04b5c669",1],
  ["0xa422defeebd4bb37be9af3270c965194d292a388",1],
  ["0x6dc3120ed771d18682d7e90d30311fa2a1069bb8",1],
  ["0xb0e3901a8567704f6442776975defc8685278b8a",1],
  ["0x17f366fc600c1a4046da052ad59b6f44483e7f12",1],
  ["0xe65ef515adc3fd724c326adf8212b4284fd10137",1],
  ["0xb826845844459d790bdb17b12a4c3f891689dce5",1],
  ["0x96096a23fc85f09d3532c6ab90cd1af2341c66bc",1],
  ["0x2aaba9472939c708b28cd186c903f535b7447b04",1],
  ["0x2884684b9824d9c911fdc3d64863bedd49d7ba8b",1],
  ["0xf21a024855384132ba9f3b3b379c412cc872d3df",1],
  ["0xbeea50d91a3e0bf7ed39a4178af78bbaee578a59",1],
  ["0x302b72cf41df9fe98cda9eeeb5e045125c4393b4",1],
  ["0xc5fe336e2c05e624e9b266edcd5b6e650831a293",1],
  ["0xef36c1e58b550e7f4b3ce4a6486f9488a0a13127",1],
  ["0xa1b54d98ee00619ccd725de2a10183ed1c20b461",1],
  ["0xbeecca08ba959dd7f2e7490a97e1daeefa59a759",1],
  ["0x561d4c86576c9e1675f1f77318ecfc18ec85d9dc",1],
  ["0x7914beeb4b2f0845c1f2064da1acf00c24fafb7b",1],
  ["0x70d32a83992e15e7291f431d30c155c0532f9a47",1],
  ["0x46570358d6202cb2c74c02e5722a65863787ddf0",1],
  ["0xbacb4db3dcc49c9493163ef9eb3e7fb0254a0d00",1],
  ["0x79c8fbb16a480b513300fb378c06d369ecc0fa6e",1],
  ["0xec7065fb417be0b374db57c174f4b56dd2fcea41",1],
  ["0x25d3beaa4963f7d7485765982474b1eaf96e0ac5",1],
  ["0x01db16cee96f08cd62865f9b29debed1d1dd020b",1],
  ["0x42e5a69d51794cea52e158b9ad5db52aa392794a",1],
  ["0xfbd6207cac6dc88cb28c1d6c84583930db2ee061",1],
  ["0xf7355577f46bd0cee842ecd9bf69f60f5629d88e",1],
  ["0x1eb5fb14419598f80ecd048c1dd84224b0ad2fcb",1],
  ["0x3f96d57b23f19ea43128b48e57ddfc4d961cf22e",1],
  ["0xea752c41711bfb50bb38eb407af5bfd3d79924b8",1],
  ["0x6146595451e5b7788d893aa6134da3ac06d23e75",1],
  ["0x4e9b4d9988ba647d53d44d2bcf794d06441bd655",1],
  ["0x5d17ba317754d41a17dce1bf9f3dbd4174d8cbac",1],
  ["0x153028817a5508669743c800b46482e1b0ef8956",1],
  ["0x4cea0b9e396bdadf051bebb8187e8ab26d41a3d6",1],
  ["0xc0fe70f3322a4db5e2eea3d41452900b8b09da83",1],
  ["0x2c94b4e30da8af02e6a624b48025b7273851d0e2",1],
  ["0x092fcf216095a4cb7ac616bd30b26c4141b39019",1],
  ["0x0eb9a136a756cf69ab82a042c6ca0bdde892eb72",1],
  ["0xd3d98b67a0482e46d38f9dd89b7bc7ace6ac566e",1],
  ["0x513cfd953d981d9010901365adf3c040ca004a74",1],
  ["0x9f3a6df7bc869b77fe3e5e050f305d9835d9192d",1],
  ["0x12e6388e6a861ba54ac60fbfff4f0ed3921fee8d",1],
  ["0x6270dba473200106b6587e2b1bca0e3d6c7485c0",1],
  ["0x2d7e104bcd2cc9753129519ffbeb58875e140bfd",1],
  ["0xa64fce7a0f8d1da32e73d0f975e23e16baf0c80c",1],
  ["0x9a8aebc8dcf878eab0ec66318ada76fb2ec79361",1],
  ["0x7a16d97773d27da30a77021e04cdb91d228be7f2",1],
  ["0x0bc69c9fd691e736fde26571a1ff7e2f3a172923",1],
  ["0xfa42b6ed16d177f5cc89a035ea92b058c850a879",1],
  ["0x1653e64bed2c98988cb11a6f786b36b0b05cc6bf",1],
  ["0x2f7618af8125f4161a2973c932c0cf5e1033160a",1],
  ["0x8dafef51a956d11fe453e19c5bcfd792ef0f238a",1],
  ["0x4c231dd4a3d3cda2d8fe98c6c497c88078ee01e4",1],
  ["0x2e48d784b32a05ae72219ca4f4fb1e927e7711f4",1],
  ["0xf8ba9079c94d3f18e7ee334adadacf4a29859310",1],
  ["0x2eae489d97fa92167d73857cc69eb900dbc05112",1],
  ["0x74fc2d23c2243cfa55ddf02ec2ce53176bfac782",1],
  ["0xe239cae5349b45f002eddb206d94fc7061968c9e",1],
  ["0x274a0e3301110d6a2059bf258a38b4df8357afec",1],
  ["0xcc9ecd906406971f7147272e4b8c861754781787",1],
  ["0xbf377d3b9f5a27c5cd7fba0ae62fb407530643fa",1],
  ["0xf9710fb00218de70b3221e2abcdf418edbe3367d",1],
  ["0x1af0bdedf5c685c0e4e7c80ab91dba2bd5a4d9f1",1],
  ["0x20ef8d8ea210ec7d74da03f753b2e63c39f230e4",1],
  ["0xf8537590ffcb32968a60ec6397d8020de32a3956",1],
  ["0x819fcccdeeac99405348df192601dd07ef1d77e9",1],
  ["0x8b392446bf66de5c82ed7b85d04e410da606877d",1],
  ["0xad6a6b2bea483b4516c97d5b6213b5f154f83e9d",1],
  ["0xa0529a6528d33c8b2002159b32b7d15b4d03b654",1],
  ["0x2b04103b3ced6c4d8fc085fe880c51705682eebc",1],
  ["0x47b8537fd88a3065a1796180ab6aacbb3777b250",1],
  ["0xec1d11d899a8ed5fcc1a03ca9ce14bbd06b24d97",1],
  ["0x5c060ba9a77222840bd625deeaa7d954b5b72427",1],
  ["0x0b15d768985d35039cfabfce8680afd535fd1556",1],
  ["0x00a1171cb791479e8b2bee0bc2399cc075f1aeab",1],
  ["0x16f665da6d806760afc317ee29ef2fef2ff4976e",1],
  ["0x019de98bef6525f616522ad72aac3ffbf78703e4",1],
  ["0x268dd0ffa7fecf60bcf76691100052d40fda7208",1],
  ["0x9e5f0b81bfd7b4fd7259969a950adecfab6765af",1],
  ["0xee11ed7693c656b2f2aa779ee05432bad1ddcb43",1],
  ["0x8ccfefe9ca15bf258d27acec3718af16d4f6b03f",1],
  ["0xdf779c6d82fdd6fb529e73e993e119e3c8d1f8cf",1],
  ["0x7395987b2853e625bd12b43aee453106089dad6c",1],
  ["0x0b96fd16d099c865e3eb8d3285445df2bff18bd0",1],
  ["0xdf5ede0d23a358da0c7c970efb52a4b3d44412e8",1],
  ["0x417487275239e0f6c5ac79f5d60135ecf118169b",1],
  ["0xdaf57b900d6c8f9c8eea64a210fe9736a11ca931",1],
  ["0x156bbeda3d519f57b3435356d765a1e69890c1a5",1],
  ["0xdf612d1bf8bb3f33dfacb6149d427e6ad0ca0e51",1],
  ["0x5421b13a22f71a745bd9f5d276766dd3d7b001fe",1],
  ["0x50063d335b85898ae7aa0b28b86db96507a5c25f",1],
  ["0xf2579606f59fb3b4182b0566ee2521ea9182cc69",1],
  ["0x11eb19ea9b86f17803a38577459e0fac21bbd772",1],
  ["0xa69b771658b39d1d7079a7c9131ba21ca8a9d3b4",1],
  ["0x907dbc8ef6003a6791fd11552b814e253e82db1b",1],
  ["0x551c8dd6f93bf8c2012d70526a8b44d3fb3f4274",1],
  ["0xe655ce812031f5554a82e8b43f2dece4646cf28f",1],
  ["0xf1ac38b5e788f89fba58a34236c2ab0dea3f0327",1],
  ["0xf34367fa03b13febf71330075396fc956d8bbea9",1],
  ["0xcfcb18a76daf95633ca762c5e46ddb5e04ade31f",1],
  ["0xa726bbcfa62d156d197c0b35c01fb19eb4977b94",1],
  ["0xd2418bc1c3b01d6ede68079e3bc784e2be5c81c8",1],
  ["0x493c6fcb0579e2f8fdb00c05ac173d0ba9866975",1],
  ["0xd20ecb03ac9c87f496da6846fd9d04a8865928b3",1],
  ["0x721e02fbe66c1ede165ac3abb335419fc3f374ef",1],
  ["0xa6326db7037c6799f4abc92b306c3beb04002b38",1],
  ["0x1b64485fe4df82467b7cf828f1a53b62bb63e475",1],
  ["0x99027fdeedc3a9d9e88f8d95175ca33987daa841",1],
  ["0xc9a32fbb0810c60b0b50708172d01294b25f11cd",1],
  ["0x1176f3b5db5948660d7ebb3ad3af78040fa048f2",1],
  ["0xec53045791ab02d6b108b05a15092d11fb6e6ce8",1],
  ["0x69c1f3471ac09760077d71a3a60125a678b507d2",1],
  ["0x82d2f81f61556f80bcda77491b3ccba4cfae1142",1],
  ["0x8e71034a814e6990a73c272d5b1db7153e32ad84",1],
  ["0x34b0becaa9685d462ed48a182cc3787710230954",1],
  ["0x6a194bb43b14a753554721d69300be7e5f8cf7e2",1],
  ["0xf04c2bb996e9c84483764dbbfadcf28e59bb137a",1],
  ["0xd6492dbda2a3e691802fa6d2264db22f9597a980",1],
  ["0xfc3780bdaeb4c6188449fd767afd0da9554018d4",1],
  ["0x2efd1ce75672657ea387efe7d7f639bc6902c313",1],
  ["0x52bbc7bdae31722b371169dbcf30071d97c620fd",1],
  ["0xf2866f07467aac2eb5115a9ea90253ecbeca6030",1],
  ["0x54f022924d6d8de34ef447cafbc875b703b233dc",1],
  ["0x1c34b21d640077362a13a9bd79f19b9c96ae7cef",1],
  ["0x4fb818166399a06aa39627b6650439a35c0881b8",1],
  ["0x5c2260103ba960d23603a7b824c80a24eae159b9",1],
  ["0x14956821d261599548124ddcc15201d80ca20fc7",1],
  ["0xfa2b615a4d773a43f14e13909491e1c0fb7c0eae",1],
  ["0xc8e4fdabdd08bebd70fdc34a8250ae85f8681415",1],
  ["0xc3b54264b43cf70bfc6149339e288c8b5c673459",1],
  ["0x434f7c4a1470a03494dff3530037082d086738a5",1],
  ["0xcc05fe80441c78d6cc66bed15a856b53f1a9dc1c",1],
  ["0x41cfd8ee3acced4b131fe713f0a7c321e23e339a",1],
  ["0x2768f72ab0f3f9a37145d1cab4bb473f239e8b6e",1],
  ["0x7e01ccb7a89dc5417c3f87cc738ae4db2c219173",1],
  ["0x21cc9c845c67646a1f6d8c48e3e2bc81ae4cac31",1],
  ["0x4187dd52368f48eb2e1db7ad079afae1a0992a2d",1],
  ["0x81f5b2538a5762467f256b0e5a07c0ac812fde67",1],
  ["0x9e588dd5bf52c4f45042e7d6ce7f42099e8973ad",1],
  ["0x65375f91a2159e472a9f3df8884dd94c5d697703",1],
  ["0x8c64d6336e4201d7bbeeb1848c2948c962ff919d",1],
  ["0xa2f175c076625c6ae49cd3c9c5ac098e0f44f131",1],
  ["0x38cdb96f0dde94aaf764392040f9a9cfb927cde1",1],
  ["0x5f0d109a9d7f6056d14aa28a0ed6a09de8704955",1],
  ["0x50c8913ffbeec5f6b4d8835fe220bd8a6b6e5c76",1],
  ["0xb240d69617c43410f5f79724d2e7d0631f595c20",1],
  ["0x90b5735051eb8fac2d32609063a278ac694ac916",1],
  ["0x24b1bc44d3415777e7378487c78c8dfabd47a3b7",1],
  ["0x162581dd29c538e3e79a49b9cbfc718544d37b66",1],
  ["0xb00799e6f3d2a0cbe91d73c3aea3970afd865740",1],
  ["0x74f5ea54f7547d58289e47d80ee539f0f6c0ac1b",1],
  ["0xb7882448d6fcf39290790eefecccd80ac7eecdc7",1],
  ["0x54a53774f216ff7aa411566067778508895299a8",1],
  ["0x8666b7f3a374ebecdbe35bee4955d7d5ae96752a",1],
  ["0x039e01717916fafd3151d366beda49989095d066",1],
  ["0x82fd2e41d34ad4016e523243ceb4354e9706c7cd",1],
  ["0x3458872b902432245d1840e8cdde55e795ebd1a5",1],
  ["0xe6eaf29c78590eec67b421d2136655458f5a8675",1],
  ["0xb55d17a1e777bb1c4ea2383569cc5760166246a3",1],
  ["0x5ccfe801655db7fbe85f29a4071cc9ffbd5a0b43",1],
  ["0xee2ad620fce911748fc870e4e36b9662270fb74e",1],
  ["0xfdb2e7640375f0ef8cb2b3e6cf9469aa457831d5",1],
  ["0xb13a9295812b6bb920dea8fff0d9878c9183f931",1],
  ["0xb618af743fe37ec708b0015736ee4c7a6583646d",1],
  ["0xe0cbfaa38c36557daf9dad1aeed7bdd1232b21a6",1],
  ["0x0d62f08d5523b310691fe4b3a681ed63fa788e50",1],
  ["0x4cdfa0b91f81dab3da1605645dd385e19986aa2f",1],
  ["0x9663c76eb6f31fe52c0fe2f24dc385236cda17ad",1],
  ["0x220c2edd9d8afa3dd17c7d675c4519f38c1bdd9b",1],
  ["0x7179af787589857ba680b5ea391acda4dada7f9a",1],
  ["0x145dbea397f71512ab97cbbf080d74d3bcc29176",1],
  ["0x35d2acea029ee298a1d28baa9d4cdfec4919194d",1],
  ["0xd3cabd5fa84102d78abb3d47fa736294d7cde8e7",1],
  ["0xb6cc21afe74c261b240c3eae86bed281a3082a8b",1],
  ["0xdf8ccecd389ca77dcb32d7727d8f1bbc0499da05",1],
  ["0xc51bdb5e389a03a5de6d915936a9f93bb0ff2e20",1],
  ["0xcb31794ebd6218ca31c8e58895a2b08d7dc76588",1],
  ["0x912a8ec70e67fc5d649eff5b019fac349415817e",1],
  ["0xdd12a0c04be3ff962e7321f11bc08dbe227c25ac",1],
  ["0x0b32b9866b6e5957299a2d248a2d0975027567cf",1],
  ["0xd33046093ff4fb5e98f2fc80f463ac49b52edee3",1],
  ["0xad1eddf3f6993f0a0d59b248ffcc0a145fe19888",1],
  ["0x92384646844c8b47cc066e092b70073390afbcc0",1],
  ["0x57eaa9569c55217ed5088cd25fd7e6f25ce77f3e",1],
  ["0x24b5c46f47654ec1f094e3046190b31ea080ce9b",1],
  
]